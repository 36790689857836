import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial } from '@react-three/drei';
import * as random from 'maath/random/dist/maath-random.esm';

const insights = [
  {
    title: 'Knowledge-Graph RAG with Internal Grounding and External Validation',
    text: 'Our latest innovation integrates knowledge graphs and ontologies into Retrieval Augmented Generation (RAG), enhancing AI-driven financial analysis. This system leverages RDF-based knowledge representation, sophisticated workflow orchestration, and dual validation processes. By combining internal grounding with real-time external validation, we have created a more accurate, context-aware AI that can handle complex financial queries while reducing inconsistencies. This approach both improves the relevance and explainability of AI-generated insights and also adapts to evolving financial landscapes.',
    image: '/static/images/insight10.png',
    link: '/insights/knowledgegraphontologyrag',
  },
  {
    title: 'Real-Time Updates in AI Systems',
    text: 'Implementing near real-time updates in AI systems, as demonstrated by Research AI, is crucial for enhancing transparency, building user trust, and preventing AI agents from becoming opaque black boxes, ultimately paving the way for more accountable and user-friendly artificial intelligence. By providing granular insights into complex processes such as PDF analysis, token estimation, ontology application, and text chunking, we not only improve the user experience but also foster a deeper understanding of AI decision-making, setting a new standard for transparency in state-of-the-art AI systems and agents.',
    image: '/static/images/insight9.gif',
    link: '/insights/realtimeupdates',
  },
  {
    title: 'Enhancing Agentic AI Workflows with Ontology Agents',
    text: 'Research AI incorporates a state-of-the-art Ontology Agent, a crucial component that significantly enhances the systems ability to understand and process financial and wealth management information. This agent acts as an intelligent intermediary between raw text data and the AI models, providing context, structure, and domain-specific knowledge to the analysis process. The Ontology Agent in Research AI operates by maintaining a comprehensive, hierarchical structure of wealth management concepts and their relationships. When processing text from financial information or user queries, the agent maps words and phrases to these predefined concepts, effectively translating raw text into a more structured, semantically rich representation.',
    image: '/static/images/insight8.gif',
    link: '/insights/ontologyagent',
  },
  {
    title: 'AI-Powered Research Analysis in Finance',
    text: 'Research AI exemplifies the cutting-edge of AI-powered financial research analysis. It leverages multiple state-of-the-art language models and AI agents, each optimised for specific tasks within a comprehensive analysis workflow. A key innovation is the integration of intermediary structures like ontologies, which bridge general language models with specialised domain knowledge, enabling nuanced, context-aware analysis of complex documents. The systems architecture efficiently handles large volumes of financial data through advanced techniques such as token management and chunking, while maintaining security and regulatory compliance. With its cutting-edge, React-based user interface, Research AI offers an immersive and intuitive experience.',
    image: '/static/images/insight7.gif',
    link: '/insights/airesearchanalysis',
  },
  {
    title: 'Integrating Cloud-Based and Edge AI in Multi-Model AI Agents',
    text: 'For businesses, an essential AI paradigm will seamlessly integrate cloud-based models like GPT-4o, Claude-3-Opus, and Command-R+ with on-premises edge AI, such as locally installed open-source models like Llama-3, to strike a balance between performance, scalability, and data privacy. However, implementing this hybrid approach is not straightforward. While spinning up an endpoint for a Llama-3 instance on a cloud service or opening a Colab notebook is relatively simple, downloading the model onto a machine and running Llama-3 in a fully secure manner is a more complex undertaking. This Insight leverages some of our recent work to expand upon these themes with real-life illustrations and code examples.',
    image: '/static/images/insight5.png',
    link: '/insights/localpluscloud',
  },
  {
    title: 'The Importance of Multi-Model Agents in Real-Life Business Settings',
    text: 'In this insight, we explore the significance of multi-model agents in real-life business settings. Multi-model agents leverage multiple AI models to perform complex tasks and provide valuable insights, enabling organizations to optimize their operations, improve decision-making, and gain a competitive edge. We discuss how multi-model agents can handle diverse data sources, enhance decision-making processes, automate complex tasks, and enable personalization at scale. Additionally, we highlight the importance of multi-model agents in mitigating model risk and provide a comprehensive multi-model agent framework with code examples.',
    image: '/static/images/insight4.png',
    link: '/insights/multimodelagents',
  },
  {
    title: 'RAG And Why It Matters',
    text: 'In this Insight, we highlight the importance of Retrieval Augmented Generation (RAG) for businesses and how RAG can transform AI into a strategic asset by delivering more precise and customized solutions. RAG enables AI models to generate text outputs grounded in factual information, reducing the risk of hallucinations or inconsistencies. We implement Coheres Command R model as a case study, illustrating how RAG can be effectively integrated into business applications. The case study provides detailed code examples to illustrate key technical aspects of the Command R RAG implementation, such as processing document snippets, generating inline citations, and optimizing architecture for efficient processing of large amounts of contextual information.',
    image: '/static/images/insight1.gif',
    link: '/insights/implementingcommandrwithrag',
  },
  {
    title: 'Claude 3 Opus: Breakthrough Performance',
    text: "The release of Anthropic's Claude 3 model family, particularly the high-end Claude 3 Opus, marked a significant milestone in the rapidly evolving landscape of large language models (LLMs). Now for the first time, Claude 3 Opus has matched OpenAI's GPT-4 at the top of the LMSYS Chatbot Arena leaderboard. The ascent of Claude 3 Opus illustrates the intensifying competition in the LLM space. In practice it means much more still. We have rapidly become heavy users of Claude 3 Opus and here we explain why, and provide practical examples.",
    image: '/static/images/insight3.png',
    link: '/insights/claudeopus',
  },
  {
    title: 'Evolutionary Model Merge',
    text: 'Sakana AI introduced the Evolutionary Model Merge (EMM) approach in March 2024, advancing towards automating the development of foundation models. The authors employ evolutionary algorithms to uncover novel and effective methods for combining open-source models. EMM is a significant development we think, not least because it offers a systematic, efficient, and innovative approach to leveraging the landscape of existing models.',
    image: '/static/images/insight2.png',
    link: '/insights/emm',
  },
];

function Background({ darkState }) {
  const ref = useRef();
  const sphere = random.inSphere(new Float32Array(5000), { radius: 1.5 });

  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 10;
    ref.current.rotation.y -= delta / 15;
  });

  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled>
        <PointMaterial
          transparent
          color={darkState ? "#00FFFF" : "#1976d2"}
          size={0.002}
          sizeAttenuation={true}
          depthWrite={false}
        />
      </Points>
    </group>
  );
}

const InsightCard = ({ insight, darkState }) => {
  const theme = useTheme();
  const textRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(textRef.current.scrollHeight > textRef.current.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <Box
      sx={{
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: darkState ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
        borderRadius: '16px',
        boxShadow: darkState ? '0 4px 30px rgba(0, 255, 255, 0.2)' : '0 4px 30px rgba(0, 0, 0, 0.1)',
        border: darkState ? '1px solid rgba(0, 255, 255, 0.3)' : '1px solid rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: darkState ? '0 14px 30px rgba(0, 255, 255, 0.4)' : '0 14px 30px rgba(0, 0, 0, 0.2)',
        },
        overflow: 'hidden',
      }}
    >
      <Box
        component="img"
        src={insight.image}
        alt={insight.title}
        sx={{
          width: '100%',
          height: 200,
          objectFit: 'cover',
        }}
      />
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            mb: 2,
            color: darkState ? '#00FFFF' : '#1976d2',
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {insight.title}
        </Typography>
        <Box ref={textRef} sx={{ flexGrow: 1, overflow: 'hidden', position: 'relative', height: 150 }}>
          <Typography
            variant="body2"
            align="justify"
            sx={{
              color: darkState ? '#ffffff' : '#000000',
              mb: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 6,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {insight.text}
          </Typography>
          {isOverflowing && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '3em',
                background: `linear-gradient(to bottom, transparent, ${darkState ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)'})`
              }}
            >
              <Typography
                variant="body2"
                align="center"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  color: darkState ? '#00FFFF' : '#1976d2',
                  fontWeight: 'bold',
                }}
              >
                More...
              </Typography>
            </Box>
          )}
        </Box>
        <Link to={insight.link} style={{ textDecoration: 'none', alignSelf: 'center', marginTop: 'auto' }}>
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: darkState ? 'rgba(0, 255, 255, 0.2)' : '#1976d2',
              color: '#ffffff',
              fontWeight: 'bold',
              px: 2,
              py: 1,
              borderRadius: '50px',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                backgroundColor: darkState ? 'rgba(0, 255, 255, 0.4)' : '#1565c0',
                transform: 'scale(1.05)',
              },
            }}
          >
            LEARN MORE
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

const Insights = ({ darkState }) => {
  const theme = useTheme();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 }
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: darkState ? '#000000' : '#f3e5f5',
        color: darkState ? '#ffffff' : '#000000',
        py: 8,
        px: 4,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Canvas
        camera={{ position: [0, 0, 1] }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      >
        <Background darkState={darkState} />
      </Canvas>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        style={{ zIndex: 1, width: '100%' }}
      >
        <motion.div variants={itemVariants}>
          <Typography
            variant="h1"
            component="h1"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              mb: 4,
              fontSize: { xs: '3rem', md: '5rem' },
              background: darkState ? 'linear-gradient(45deg, #00ffff, #00bfff)' : 'linear-gradient(45deg, #1976d2, #1565c0)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textShadow: darkState ? '0 0 10px rgba(0, 255, 255, 0.5)' : 'none',
            }}
          >
            Insights
          </Typography>
        </motion.div>

        <Grid container spacing={4} justifyContent="center">
          {insights.map((insight, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div variants={itemVariants}>
                <InsightCard insight={insight} darkState={darkState} />
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </motion.div>
    </Box>
  );
};

export default Insights;